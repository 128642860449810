<template>
  <b-modal
    id="parcel-event-history"
    ref="parcel-event-history"
    :title='`Events History: ${parcel.tracking_number}`'
    scrollable
    hide-footer
    centered
    @hidden="reset"
    @shown="init"
  >
    <b-container>
      <b-row>
        <b-col>
          <div
            v-if="!events.length"
            class="text-center my-4"
          >
            <b-spinner
              v-if="loading"
              class="mb-1"
            />
            <h6 v-if="loading">
              Searching parcel events...
            </h6>
            <h6 v-else>
              This parcel doesn't have events yet...
            </h6>
          </div>
          <app-timeline
            v-else
            class="my-2"
          >
            <app-timeline-item
              v-for="(event, index) in events"
              :key="index"
              :time="event.at"
              :title="getTypeLabel(event.type)"
              :variant="variantMap[event.type] || 'primary'"
            />
          </app-timeline>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BContainer, BCol, BSpinner,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimelineItem,
    AppTimeline,
    BModal,
    BRow,
    BContainer,
    BCol,
    BSpinner,
  },
  props: {
    parcel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      events: [],
      types: [],
    }
  },
  computed: {
    variantMap() {
      return {
        cleared: 'success',
        cleared_manually: 'success',
        needs_documents: 'warning',
        errored: 'warning',
        held: 'danger',
        confiscated: 'danger',
        arrived_to_facility: 'secondary',
        ready_for_last_mile: 'secondary',
        released_for_last_mile: 'secondary',
      }
    },
  },
  methods: {
    init() {
      if (!this.types.length) {
        this.fetchStatuses()
      }
      this.loadHistory()
    },
    fetchStatuses() {
      this.$http.get('/v1/api/events/statuses')
        .then(response => {
          this.types = response.data.data
        })
    },
    getTypeLabel(type) {
      const typeElement = this.types.find(item => item.value === type)
      if (typeElement) {
        return typeElement.label
      }
      return type
    },
    loadHistory() {
      this.events = []
      this.loading = true
      this.$http.get(
        `/v1/api/companies/${this.parcel.shipment.company_id}/events`
            + `?parcel_tracking_number=${this.parcel.tracking_number}`
            + '&categories=warehouse,customs'
            + `&document_number=${this.parcel.shipment.document_number}`,
      )
        .then(response => {
          this.events = response.data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    reset() {
      this.events = []
      this.loading = true
    },
  },
}
</script>
