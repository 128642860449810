<template>
  <!-- Seller data -->
  <b-row>
    <b-col
      lg="3"
      md="4"
    >
      <label>Invoice reference number</label>
      <b-form-input
        v-model="parcel.invoice_reference_number"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Delivery terms country</label>
      <b-form-input
        v-model="parcel.delivery_terms_country"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Delivery terms location name</label>
      <b-form-input
        v-model="parcel.delivery_terms_location_name"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Delivery terms code</label>
      <b-form-input
        v-model="parcel.delivery_terms_code"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>
