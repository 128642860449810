<template>
  <b-modal
    :id="this.$attrs.id"
    ref="edit-parcel-items-modal"
    title="Parcel & Declaration details"
    size="xl"
    scrollable
    no-close-on-backdrop
  >
    <clear-manually
      style="top: 100px"
      :show="showClearManually"
      :declaration="declaration"
      :event="eventHub"
      :shipment-id="shipmentId"
      @close="closeClearManually()"
      @saved="clearManuallySaved()"
    />
    <invalidation-modal
      v-if="canEdit && declaration && shipment"
      style="top: 100px"
      :declaration="declaration"
      :shipment-id="shipmentId"
      :company-id="shipment.company_id"
      :event="eventHub"
      @invalidated="declarationInvalidated()"
    />
    <b-card-text>
      <b-form>
        <app-collapse>
          <app-collapse-item
            id="parcel-declaration"
            :title="parcelDeclarationTitle"
            class="mb-1"
          >
            <declaration-data-component
              v-if="declaration && shipment"
              :declaration="declaration"
              :shipment="shipment"
              :disabled="disableChanges"
            />

            <parcel-data-component
              :parcel="parcel"
              :declaration="declaration"
              :disabled="disableChanges"
            />

            <b-row>
              <b-col>
                <b-card
                  id="ParcelItemsCard"
                  class="mt-1"
                >
                  <b-row>
                    <b-col>
                      <h4 class="card-title">
                        Parcel items
                      </h4>
                    </b-col>
                    <b-col v-if="!disableChanges">
                      <b-button
                        id="add-parcel-item"
                        variant="outline-success"
                        size="sm"
                        class="float-right"
                        @click="addParcelItem()"
                      >
                        <BIconPlus />
                        Add row
                      </b-button>
                    </b-col>
                  </b-row>
                  <validation-observer ref="parcelItemsValidation">
                    <parcel-item-data-component
                      :parcel="parcel"
                      :disabled="disableChanges"
                      :declaration="declaration"
                      @remove="removeItem($event)"
                    />
                  </validation-observer>
                </b-card>
              </b-col>
            </b-row>

            <b-container fluid>
              <hr>
              <b-row v-if="declaration">
                <b-col
                  v-if="canCancelDeclaration"
                >
                  <b-button
                    v-b-modal.invalidate-declaration-modal
                    variant="danger"
                  >
                    Cancel declaration
                  </b-button>
                </b-col>
                <b-col
                  class="text-right"
                >
                  <b-button
                    v-if="canEdit && shipmentId && !['cleared', 'cleared_manually'].includes(declaration.status)"
                    variant="secondary"
                    class="mr-2"
                    @click="clearManually()"
                  >
                    Clear manually
                  </b-button>
                  <b-button
                    v-if="!disableChanges"
                    variant="primary"
                    @click="confirmDeclaration()"
                  >
                    Submit amendments
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col
                  v-if="!disableChanges"
                  class="text-right"
                >
                  <b-button
                    variant="primary"
                    @click="confirmDeclaration()"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </app-collapse-item>
        </app-collapse>

        <supporting-documents-component
          v-if="declaration"
          :shipment-id="shipmentId"
          :parcel-id="parcel.id"
          :declaration="declaration"
          :questions="declaration.customs_questions"
          :event="eventHub"
          :disabled="readonly || (!declaration.can_send_customs_message && !declaration.can_reply_to_customs_communications)"
        />

        <notes-component
          :company-id="companyId"
          :parcel-id="parcel.id"
        />
        <attachment-component
          :company-id="companyId"
          :parcel-id="parcel.id"
        />
      </b-form>
    </b-card-text>

    <template #modal-footer="{ close }">

      <b-container fluid>
        <b-row>
          <b-col class="text-right">
            <b-button
              variant="secondary"
              @click="close"
            >
              Close
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>

  </b-modal>
</template>

<script>
import {
  BButton,
  BCardText,
  BCol,
  BContainer,
  BRow,
  BModal,
  BForm,
  BCard,
  BIconPlus,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import ParcelDataComponent from '@/views/shipments/declarations/modals/components/ParcelDataComponent.vue'
import ParcelItemDataComponent from '@/views/shipments/declarations/modals/components/ParcelItemDataComponent.vue'
import SupportingDocumentsComponent from '@/views/shipments/declarations/modals/components/SupportingDocumentsComponent.vue'
import NotesComponent from '@/views/shipments/declarations/modals/components/NotesComponent.vue'
import AttachmentComponent from '@/views/shipments/declarations/modals/components/AttachmentComponent.vue'
import DeclarationDataComponent from '@/views/shipments/declarations/modals/components/DeclarationDataComponent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ClearManually from '@/views/shipments/declarations/modals/components/ClearManually.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InvalidationModal from '@/views/shipments/declarations/modals/components/InvalidationModal.vue'

export default {
  components: {
    InvalidationModal,
    ValidationObserver,
    ClearManually,
    BCard,
    AppCollapse,
    AppCollapseItem,
    SupportingDocumentsComponent,
    NotesComponent,
    AttachmentComponent,
    DeclarationDataComponent,
    ParcelItemDataComponent,
    ParcelDataComponent,
    BButton,
    BCardText,
    BCol,
    BContainer,
    BRow,
    BModal,
    BForm,
    BIconPlus,
  },
  props: {
    declaration: {
      type: Object,
      default: () => {},
    },
    eventHub: {
      type: Object,
      required: true,
    },
    parcel: {
      type: Object,
      required: true,
    },
    shipment: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        { key: 'item_number', label: 'Item number' },
        { key: 'description', label: 'Description' },
        { key: 'hs_code', label: 'HS Code' },
        { key: 'value', label: 'Value' },
        { key: 'value_currency', label: 'Value Currency' },
      ],
      showClearManually: false,
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    companyId() {
      return this.shipment?.company_id || this.$activeCompany().data.company.id
    },
    canCancelDeclaration() {
      return this.declaration.declaration_type === 'H7'
        && !['cancelled'].includes(this.declaration.status)
        && ['FI', 'LV', 'RO'].includes(this.declaration.customs_country)
    },
    disableChanges() {
      return this.readonly
        || (
          this.declaration
          && (['cleared', 'cleared_manually'].includes(this.declaration.status)
          || !this.declaration.can_send_amendment)
        )
    },
    shipmentId() {
      if (this.shipment) {
        return this.shipment.id
      }
      if (this.parcel) {
        return this.parcel.shipment_id
      }
      return null
    },
    parcelDeclarationTitle() {
      if (this.declaration && this.shipment) {
        return 'Parcel and declaration'
      }
      return 'Parcel'
    },
  },
  methods: {
    removeItem(id) {
      const item = this.parcel.items.find(x => x.id === id || x.new_item === id)
      this.parcel.items = this.parcel.items.filter(x => x.id !== id && x.new_item !== id)

      if (item.id) {
        this.$http.delete(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.parcel.shipment_id}/parcels/${this.parcel.id}/items/${id}`, this.parcel).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Parcel item removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
      }
    },
    addParcelItem() {
      const countNewItems = this.parcel.items.filter(x => x.new_item).length + 1
      this.parcel.items.push({
        new_item: `new_${countNewItems}`,
        item_number: '',
        description: '',
        quantity: '',
        hs_code: '',
        value: '',
        value_currency: '',
        gross_weight: '',
      })
    },
    confirmDeclaration() {
      this.$refs.parcelItemsValidation.validate().then(success => {
        if (!success) {
          return
        }
        if (this.declaration && this.declaration.id) {
          const result = window.confirm('You have changed declaration data, confirm to send amendment to customs.')
          if (result) {
            this.$refs['edit-parcel-items-modal'].hide()
            this.updateParcelItems()
          }
        } else {
          this.updateParcelItems()
        }
      })
    },
    getItemError(index, errorList) {
      let result = false
      if (errorList) {
        errorList.errors.forEach(error => {
          if (error.item_reference && parseInt(error.item_reference, 10) === index + 1) {
            result = error
          }
        })
      }

      return result
    },
    updateParcelItems() {
      this.$http.put(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.parcel.shipment_id}/parcels/${this.parcel.id}`, this.parcel).then(() => {
        this.eventHub.$emit('update-parcel-items', this.parcel.id)
      })
      if (this.declaration && this.declaration.id) {
        this.$http.put(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.parcel.shipment_id}/declarations/${this.declaration.id}`, this.declaration)
        this.eventHub.$emit('update-declaration-model', this.declaration)
      }
    },
    clearManually() {
      this.showClearManually = true
    },
    closeClearManually() {
      this.showClearManually = false
    },
    clearManuallySaved() {
      this.showClearManually = false
      this.$refs['edit-parcel-items-modal'].hide()
    },
    declarationInvalidated() {
      this.$bvModal.hide('invalidate-declaration-modal')
      this.$refs['edit-parcel-items-modal'].hide()
    },
  },
}
</script>
<style>
[aria-expanded=true].card-header {
  box-shadow: 0 6px 7px rgba(102, 102, 102, 0.1);
  margin-bottom: 6px;
}
</style>
