<template>
  <div>
    <b-row class="overflow-x-scroll">
      <b-table
        :items="parcel.items"
        :fields="tableFields"
        class="compact-headers mx-1"
      >
        <template #cell(rowNumber)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(description)="data">
          <div v-if="disabled">
            {{ data.item.description }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-input
                id="description"
                v-model="data.item.description"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(hs_code)="data">
          <div v-if="disabled">
            {{ data.item.hs_code }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="HS Code"
              rules="required"
            >
              <b-form-input
                id="hs-code"
                v-model="data.item.hs_code"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(quantity)="data">
          <div v-if="disabled">
            {{ data.item.quantity }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Quantity"
              rules="required"
            >
              <b-form-input
                id="quantity"
                v-model="data.item.quantity"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(value)="data">
          <div v-if="disabled">
            {{ data.item.value }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Value"
              rules="required"
            >
              <b-form-input
                id="value"
                v-model="data.item.value"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(value_currency)="data">
          <div v-if="disabled">
            {{ data.item.value_currency }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Currency"
              rules="required|alpha|length:3"
            >
              <b-form-input
                id="value-currency"
                v-model="data.item.value_currency"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(gross_weight)="data">
          <div v-if="disabled">
            {{ data.item.gross_weight }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Gross weight"
              rules="required"
            >
              <b-form-input
                id="gross-weight"
                v-model="data.item.gross_weight"
                class="full-width"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(net_weight)="data">
          <div v-if="disabled">
            {{ data.item.net_weight }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Net weight"
              rules="required"
            >
              <b-form-input
                id="net-weight"
                v-model="data.item.net_weight"
                class="full-width"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(supplementary_unit)="data">
          <div v-if="disabled">
            {{ data.item.supplementary_unit }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Supplementary Unit"
            >
              <b-form-input
                id="supplementary-unit"
                v-model="data.item.supplementary_unit"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(origin_country)="data">
          <div v-if="disabled">
            {{ data.item.origin_country }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Country of origin"
            >
              <b-form-input
                id="origin-country"
                v-model="data.item.origin_country"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(supplementary_unit_value)="data">
          <div v-if="disabled">
            {{ data.item.supplementary_unit_value }}
          </div>
          <div v-else>
            <validation-provider
              #default="{ errors }"
              name="Supplementary Unit Value"
            >
              <b-form-input
                id="supplementary-unit-value"
                v-model="data.item.supplementary_unit_value"
                :state="errors.length > 0 ? false:null"
              />
            </validation-provider>
          </div>
        </template>

        <template #cell(action)="data">
          <b-button
            variant="outline-danger"
            style="bottom: 4px"
            @click="deleteItem(data.item.id || data.item.new_item)"
          >
            <feather-icon
              icon="TrashIcon"
              class="d-inline"
            />
          </b-button>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <!-- Errors -->
      <b-col
        v-if="error"
        lg="1"
      >
        <label>Errors</label>
        <b-alert
          show
          variant="danger"
        >
          {{ error.message }}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BAlert,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BButton,
    ValidationProvider,
    BCol,
    BRow,
    BFormInput,
    BTable,
    BAlert,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    declaration: {
      type: Object,
      default: () => {},
    },
    error: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const fields = [
      { key: 'rowNumber', label: '#' },
      { key: 'description', label: 'Description' },
      { key: 'hs_code', label: 'HS Code' },
      { key: 'quantity', label: 'Quantity' },
      { key: 'value', label: 'Value' },
      { key: 'value_currency', label: 'Currency' },
      { key: 'gross_weight', label: 'Gross weight, kg' },
    ]
    if (this.parcel.declaration_type === 'H1') {
      fields.push({ key: 'origin_country', label: 'Country of origin' })
      fields.push({ key: 'net_weight', label: 'Net weight' })
    }
    return {
      fields: fields,
    }
  },
  computed: {
    totalItems() {
      return this.parcel.items.length
    },
    tableFields() {
      const optionalHeader = []

      if (this.declaration && this.declaration.customs_country === 'RO') {
        optionalHeader.push({ key: 'supplementary_unit', label: 'Supplementary Unit' })
        optionalHeader.push({ key: 'supplementary_unit_value', label: 'Supplementary Unit Value' })
      }

      const actionHeader = !this.disabled && this.totalItems > 1 ? [{ key: 'action', label: '' }] : []

      return [...this.fields, ...optionalHeader, ...actionHeader]
    },
  },
  methods: {
    deleteItem(id) {
      this.$emit('remove', id)
    },
  },
}
</script>
<style>
.compact-headers td, .compact-headers th {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
