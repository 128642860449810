<template>
  <!-- Buyer data -->
  <b-row v-if="parcel.buyer">
    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer name</label>
      <b-form-input
        v-model="parcel.buyer.name"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer registry number</label>
      <b-form-input
        v-model="parcel.buyer.registry_number"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer street number</label>
      <b-form-input
        v-model="parcel.buyer.street_and_number"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer postal code</label>
      <b-form-input
        v-model="parcel.buyer.postal_code"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer city</label>
      <b-form-input
        v-model="parcel.buyer.city"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer region</label>
      <b-form-input
        v-model="parcel.buyer.region"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer country</label>
      <b-form-input
        v-model="parcel.buyer.country"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer email</label>
      <b-form-input
        v-model="parcel.buyer.email"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>

    <b-col
      lg="3"
      md="4"
    >
      <label>Buyer phone</label>
      <b-form-input
        v-model="parcel.buyer.phone"
        :disabled="disabled"
        class="mr-0 ml-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    parcel: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
}
</script>
