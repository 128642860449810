var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"overflow-x-scroll"},[_c('b-table',{staticClass:"compact-headers mx-1",attrs:{"items":_vm.parcel.items,"fields":_vm.tableFields},scopedSlots:_vm._u([{key:"cell(rowNumber)",fn:function(data){return [_vm._v(" "+_vm._s(data.index + 1)+" ")]}},{key:"cell(description)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.description)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false:null},model:{value:(data.item.description),callback:function ($$v) {_vm.$set(data.item, "description", $$v)},expression:"data.item.description"}})]}}],null,true)})],1)]}},{key:"cell(hs_code)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.hs_code)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"HS Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hs-code","state":errors.length > 0 ? false:null},model:{value:(data.item.hs_code),callback:function ($$v) {_vm.$set(data.item, "hs_code", $$v)},expression:"data.item.hs_code"}})]}}],null,true)})],1)]}},{key:"cell(quantity)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.quantity)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"quantity","state":errors.length > 0 ? false:null},model:{value:(data.item.quantity),callback:function ($$v) {_vm.$set(data.item, "quantity", $$v)},expression:"data.item.quantity"}})]}}],null,true)})],1)]}},{key:"cell(value)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.value)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","state":errors.length > 0 ? false:null},model:{value:(data.item.value),callback:function ($$v) {_vm.$set(data.item, "value", $$v)},expression:"data.item.value"}})]}}],null,true)})],1)]}},{key:"cell(value_currency)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.value_currency)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Currency","rules":"required|alpha|length:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value-currency","state":errors.length > 0 ? false:null},model:{value:(data.item.value_currency),callback:function ($$v) {_vm.$set(data.item, "value_currency", $$v)},expression:"data.item.value_currency"}})]}}],null,true)})],1)]}},{key:"cell(gross_weight)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.gross_weight)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Gross weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"full-width",attrs:{"id":"gross-weight","state":errors.length > 0 ? false:null},model:{value:(data.item.gross_weight),callback:function ($$v) {_vm.$set(data.item, "gross_weight", $$v)},expression:"data.item.gross_weight"}})]}}],null,true)})],1)]}},{key:"cell(net_weight)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.net_weight)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Net weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"full-width",attrs:{"id":"net-weight","state":errors.length > 0 ? false:null},model:{value:(data.item.net_weight),callback:function ($$v) {_vm.$set(data.item, "net_weight", $$v)},expression:"data.item.net_weight"}})]}}],null,true)})],1)]}},{key:"cell(supplementary_unit)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.supplementary_unit)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Supplementary Unit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplementary-unit","state":errors.length > 0 ? false:null},model:{value:(data.item.supplementary_unit),callback:function ($$v) {_vm.$set(data.item, "supplementary_unit", $$v)},expression:"data.item.supplementary_unit"}})]}}],null,true)})],1)]}},{key:"cell(origin_country)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.origin_country)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Country of origin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"origin-country","state":errors.length > 0 ? false:null},model:{value:(data.item.origin_country),callback:function ($$v) {_vm.$set(data.item, "origin_country", $$v)},expression:"data.item.origin_country"}})]}}],null,true)})],1)]}},{key:"cell(supplementary_unit_value)",fn:function(data){return [(_vm.disabled)?_c('div',[_vm._v(" "+_vm._s(data.item.supplementary_unit_value)+" ")]):_c('div',[_c('validation-provider',{attrs:{"name":"Supplementary Unit Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"supplementary-unit-value","state":errors.length > 0 ? false:null},model:{value:(data.item.supplementary_unit_value),callback:function ($$v) {_vm.$set(data.item, "supplementary_unit_value", $$v)},expression:"data.item.supplementary_unit_value"}})]}}],null,true)})],1)]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{staticStyle:{"bottom":"4px"},attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteItem(data.item.id || data.item.new_item)}}},[_c('feather-icon',{staticClass:"d-inline",attrs:{"icon":"TrashIcon"}})],1)]}}])})],1),_c('b-row',[(_vm.error)?_c('b-col',{attrs:{"lg":"1"}},[_c('label',[_vm._v("Errors")]),_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }