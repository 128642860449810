<template>
  <b-row>
    <b-col>
      <b-card title="Filters">
        <b-form v-on:keyup.enter="filterData">

          <b-row>
            <b-col>
              <b-form-group
                label="Document number"
                label-for="document-number"
              >
                <b-form-input
                  id="document-number"
                  v-model="filters.document_number"
                  name="document-number"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Date range"
                label-for="date-range"
              >
                <flat-pickr
                  id="date-range"
                  v-model="filters.dateRange"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Status"
                label-for="status"
              >
                <v-select
                  v-model="selectedFilterStatus"
                  :options="statusOptions"
                  :clearable="false"
                  input-id="status"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="LRN"
                label-for="lrn"
              >
                <b-form-input
                  id="lrn"
                  v-model="filters.lrn"
                  name="lrn"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="MRN"
                label-for="mrn"
              >
                <b-form-input
                  id="mrn"
                  v-model="filters.mrn"
                  name="mrn"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Container number"
                label-for="container-number"
              >
                <b-form-input
                  id="container-number"
                  v-model="filters.container_code"
                  name="container-number"
                  autocomplete="off"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Has note or file"
                label-for="has-note"
              >
                <b-form-checkbox
                  id="has-note"
                  v-model="filters.has_note"
                  switch
                  class="mt-50"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Company name filter -->
            <b-col v-if="companyList.length > 1">
              <b-form-group
                label="Company"
                label-for="company"
              >
                <v-select
                  id="company"
                  v-model="filters.companyIds"
                  :reduce="option => option.value"
                  label="label"
                  multiple
                  :options="suggestionList"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between">
                <b-form-group
                  label="Parcel tracking number"
                  label-for="parcel-tracking-number"
                  class="full-width"
                >
                  <b-input-group>
                    <b-form-tags
                      id="parcel-tracking-number"
                      v-model="filters.tracking_number"
                      label="Parcel tracking number"
                      :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
                      separator=" ,;"
                      placeholder="Enter tracking codes separated by space, comma or semicolon"
                      no-add-on-enter
                      add-on-change
                      clearable
                    />
                    <b-input-group-append v-if="filters.tracking_number.length">
                      <b-button
                        id="filter-reset"
                        variant="secondary"
                        size="sm"
                        @click="filters.tracking_number = []"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <div class="ml-2">
                  <b-button
                    id="apply-filters-btn"
                    class="mt-2"
                    type="button"
                    variant="primary"
                    @click="filterData()"
                  >
                    Filter
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <parcels-table
        :table-data="data.parcels"
        :event-hub="data.eventHub"
        :statuses="statusOptions"
        :show-logo-column="companyList.length > 1"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Vue from 'vue'
import vSelect from 'vue-select'
import ParcelsTable from '@/views/parcels/ParcelsTable.vue'
import { getSelectedCompanies, parseFilters, updateSelectedCompanies } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    BFormCheckbox,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    ParcelsTable,
    BCard,
    vSelect,
  },
  data() {
    return {
      statusOptions: [
        { label: 'N/A', value: '', variant: '' },
        { label: 'Cleared', value: 'cleared', variant: 'light-success' },
        { label: 'Cleared manually', value: 'cleared_manually', variant: 'light-success' },
        { label: 'Waiting for arrival', value: 'accepted', variant: 'info' },
        { label: 'Cancelled', value: 'cancelled', variant: 'info' },
        { label: 'Preparing to send', value: 'preparing_to_send', variant: 'info' },
        { label: 'Waiting for customs', value: 'waiting_for_customs', variant: 'info' },
        { label: 'Archived', value: 'truncated', variant: 'secondary' },
        { label: 'Not sent to customs', value: 'not-sent', variant: 'warning' },
        { label: 'Held by customs', value: 'held', variant: 'warning' },
        { label: 'Needs documents', value: 'needs-documents', variant: 'warning' },
        { label: 'Needs attention', value: 'needs-attention', variant: 'warning' },
        { label: 'Draft submitted', value: 'draft_created', variant: 'warning' },
        { label: 'Price over 150€ - H7 not available', value: 'over-150', variant: 'danger' },
        { label: 'Prohibited articles - H7 not available', value: 'prohibited', variant: 'danger' },
        { label: 'HS codes missing', value: 'missing-hs-codes', variant: 'danger' },
        { label: 'Reduced VAT rate', value: 'reduced-vat', variant: 'danger' },
        { label: 'All arrived, not cleared', value: 'declaration-sent', variant: 'info' },
        { label: 'Available for last mile', value: 'ready-for-last-mile-available', variant: 'info' },
      ],
      filters: {
        document_number: '',
        container_code: '',
        has_note: null,
        tracking_number: [],
        lrn: '',
        mrn: '',
        dateRange: '',
        status: '',
        companyIds: getSelectedCompanies(),
      },
      companyList: [],
      selectedFilterStatus: null,
      data: {
        eventHub: new Vue(),
        shipments: [],
      },
    }
  },
  computed: {
    suggestionList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
  },
  created() {
    this.$data.companyList = this.$profile().data.user.companies
  },
  mounted() {
    if (this.$route.params) {
      if (this.$route.params.filters) {
        this.filters = this.$route.params.filters
      }
      this.selectedFilterStatus = this.statusOptions.find(status => status.label === this.$route.params.status)
      this.filterData()
    }
  },
  methods: {
    filterData() {
      if (this.selectedFilterStatus) {
        this.filters.status = this.selectedFilterStatus.value
      }
      const filters = { ...this.filters }
      filters.tracking_number = this.filters.tracking_number.join(',')
      this.data.eventHub.$emit('updateFilters', parseFilters(filters))
      updateSelectedCompanies(this.filters.companyIds)
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#apply-filters-btn {
  padding-top: .63rem;
  padding-bottom: .63rem;
}
</style>
