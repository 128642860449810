<template>
  <b-card
    v-if="parcel"
    title="Parcel data"
  >
    <b-row>
      <b-col
        lg="3"
        md="4"
      >
        <label>Tracking code</label>
        <b-form-input
          v-model="parcel.tracking_number"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Container code</label>
        <b-form-input
          v-model="parcel.container_code"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>TSD position</label>
        <b-form-input
          v-model="parcel.tsd_position"
          :disabled="disabled"
          type="number"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Transport Cost</label>
        <b-form-input
          v-model="parcel.transport_cost"
          :disabled="disabled"
          type="number"
          class="mr-0 ml-0"
        />
      </b-col>
      <b-col
        lg="3"
        md="4"
      >
        <label>Transport Cost Currency</label>
        <b-form-input
          v-model="parcel.transport_cost_currency"
          :disabled="disabled"
          class="mr-0 ml-0"
        />
      </b-col>
    </b-row>

    <buyer-data-component :parcel="parcel" :disabled="disabled" />
    <seller-data-component :parcel="parcel" :disabled="disabled" />
    <h1-data-component
      v-if="parcel.declaration_type === 'H1'"
      :parcel="parcel"
      :disabled="disabled"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BCard,
} from 'bootstrap-vue'
import BuyerDataComponent from '@/views/shipments/declarations/modals/components/BuyerDataComponent.vue'
import SellerDataComponent from '@/views/shipments/declarations/modals/components/SellerDataComponent.vue'
import H1DataComponent from '@/views/shipments/declarations/modals/components/H1DataComponent.vue'

export default {
  components: {
    H1DataComponent,
    BCard,
    BuyerDataComponent,
    SellerDataComponent,
    BRow,
    BCol,
    BFormInput,
  },
  props: ['parcel', 'disabled', 'declaration'],
}
</script>
